export const privacyText = `
# プライバシーポリシー（個人情報保護方針）

本プライバシーポリシーは、株式会社 Opening Line（以下「弊社」といいます。）のサービスを利用する利用者（以下「利用者」といいます。）の個人情報（個人情報の保護に関する法律により定義される意味を持つものとし、(i)他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるもの、及び(ii)個人識別符号が含まれるものを含みます。）をどのように取得、利用、開示、移転および保存するかを規定するものです。

## 1. 適用範囲

本プライバシーポリシーは、弊社のサービスの利用規約およびこれに付随する規約またはガイドラインとともに、弊社と利用者との間に適用されます。

## 2. 取得情報

弊社が取得する可能性のある情報は以下のとおりです（以下「取得情報」といいます。）。

（1）利用者から直接取得する情報

弊社は、利用者が弊社のサービスのユーザー登録をする際に以下の情報を利用者から直接取得し、保管する場合があります。

a. 氏名、住所、職業、電話番号、メールアドレス、年齢、性別、生年月日

b. その他弊社が指定する利用者に関する情報

（2）端末情報

利用者が端末または携帯端末上で弊社のサービスを利用する場合、弊社は、端末識別子、携帯端末識別子、IP アドレスおよび端末にインストールされている弊社のアプリケーションのリストを取得する場合があります。また、弊社は、端末の種類、端末に関連づけられた氏名、居住地、電話番号、メールアドレス、ユーザー名等の情報を取得する場合があります。

（3）位置情報

利用者が端末または携帯端末上で弊社のサービスを利用する場合、弊社は、利用者の現在地に関する情報を取得することがあります。

（4）カスタマーサポートに関する情報

弊社のカスタマーサポートを受ける場合、利用者が提供する連絡先情報（通常は氏名とメールアドレスですがこれらに限られません。）やサポートの際のやり取りに含まれる情報等を取得することがあります。

（5）利用者のご利用状況に関する情報

弊社は、利用者のサービスご利用状況に関する情報を取得することがあります。

（6）クッキー等を利用した情報取得

弊社および弊社の代理となるサービス提供者は、クッキー等のログファイルを使用し、上記（2）で定める端末情報のほか、利用者のブラウザの種類、ブラウザの言語、プラットフォームの種類、等の情報を取得する場合があります。

（7）外部業者との連携により取得する情報

弊社は、弊社がサービスに関して提携する外部業者が取得した利用者の情報を、当該外部業者のプライバシーポリシーにより認められる範囲で、当該外部業者から提供を受け、取得することがあります（この場合、弊社は、当該取得情報を本プライバシーポリシーに従って利用します。）。

## 3. 取得情報の利用目的

弊社は、取得情報を以下の目的のために利用します。

（1）弊社のサービス等の運営および各種手続のため

（2）弊社のサービス等に関する通知または弊社のサービス等の保守・変更・障害対応のため

（3）利用者からのご意見・ご感想をいただくため

（4）利用者からのお問い合わせや資料請求などに対応するため

（5）市場調査や新しい商品・サービスの開発のため

（6）各種イベント・セミナー・キャンペーン・会員制サービスなどの案内のため

（7）電子メール配信サービスや刊行物などの発送のため

（8）弊社または提携先で取り扱っている商品やサービスに関する情報の提供のため

（9）有料サービスに係わる料金のご請求・決済のため

（10）会計監査上の確認作業のため

（11）その他弊社の事業に付帯・関連する事項のため

## 4. 第三者への提供

弊社は、次のいずれかの場合、取得情報を第三者に提供することがあります。

（1）第三者への提供が法令上制限されていない場合、および法令に基づき提供する場合

（2）開示について利用者の同意があった場合（外国に所在する第三者の場合、外国に所在する第三者に対する開示について、利用者に対して法令の定めにより求められる情報提供を行った上で、利用者の同意を得た場合）

（3）弊社のサービス等を運営する上で必要なソフトウェアやインフラストラクチャーのベンダーに対して必要な限度で開示する場合

（4）弊社の有料サービス等について決済サービス提供者に必要な限度で開示する場合

（5）利用目的の達成に必要な範囲内において取得情報の取扱いの全部又は一部を委託することに伴って当該取得情報が提供される場合

（6）特定の者との間で共同して利用される取得情報が当該特定の者に提供される場合であって、その旨並びに共同して利用される取得情報の項目、共同して利用する者の範囲、利用する者の利用目的並びに当該取得情報の管理について責任を有する者の氏名又は名称及び住所並びに法人にあってはその代表者の氏名について、あらかじめ、利用者に通知しまたは利用者が容易に知り得る状態に置いている場合

（7）人の生命、身体または財産の保護のために必要がある場合や公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、利用者の同意を得ることが困難であるとき

（8）国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、利用者の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき

## 5. 取得情報の管理

弊社は、取得した個人情報の紛失、盗用、悪用、不正なアクセス・開示・改ざんおよび破壊を防ぐための以下の合理的な管理上の措置を講じています。

(1)基本方針の策定

・ 個人データの適正な取扱いの確保のため、「関係法令・ガイドライン等の遵守」、「質問及び苦情処理の窓口」等についての基本方針として個人情報保護方針を策定しています。

(2)個人データの取扱いに係る規律の整備

・ 個人データの取得、利用、保存、提供、削除・廃棄等の各段階における取扱い方法を定めた社内規程を策定しています。

(3)組織的安全管措置

・ 個人データの取り扱いに関する管理責任者を設置するとともに、個人データを取り扱う従業者及び取り扱う個人データの範囲を明確化しています。またアクセス権限の定期的な見直しを実施しています。

・ 社内規程等への違反及び個人データの漏洩や紛失等の事案が発生している事実又は兆候を把握した場合の責任者への報告連絡体制を整備しています。

・ インシデントへの対応を速やかにできるよう体制を整備しています。

(4)人的安全管理措置

・ 個人データの取扱い及び情報セキュリティに関する留意事項について、全従業者に定期的な研修とその理解度の確認を実施し、さらなる意識の向上を図っています。

・ 個人データについての秘密保持に関する事項を就業規則に規定し、また入社時に従業員から誓約書を徴収しています。

(5)物理的安全管理措置

・ 執務エリアにおける従業者の入退室管理を実施しています。

・ 個人データを取り扱う機器、電子媒体及び書類等の盗難又は紛失等を防止するための措置を講じるとともに、事業所内の移動を含め、当該機器、電子媒体等を持ち運ぶ場合、暗号化や施錠等を行い盗難又は紛失等が発生した際の影響を最小限にする措置を実施しています。

(6)技術的安全管理措置

・ 個人データを取り扱う従業者及び取り扱う個人データを限定しています。

・ 個人データを取り扱うシステムを外部からの不正アクセス又は不正ソフトウェアから保護する仕組みを導入するとともに、アクセスログ等をモニタリングしています。

・ 個人データを取り扱うシステムに対し、外部機関による脆弱性診断の実施や、常日頃から最新セキュリティ情報を取得するとともに、リスクの評価と適切な対応に努めています。

(7)外的環境について

・ 外国において個人データを取り扱う場合、当該外国の個人情報の保護に関する制度等を把握した上で、安全管理のために必要かつ適切な措置を講じています。

## 6. 取得情報へのアクセス

弊社は、利用者から、取得情報の開示、内容の訂正、追加、削除または利用の停止、消去を請求されたときは、法令に従い誠実に対応します。請求に際しては、当社所定の書類及びご本人であることを確認する証明書をご提出頂きます。具体的な手続については、下記のお問い合わせ先までご連絡ください。なお、利用者からの要請に対応する前に、利用者にご本人様確認をお願いすることがあります。弊社は、不合理に繰り返し行われる要請、過度の技術的努力を要する要請、他者のプライバシーを害するおそれのある要請、著しく非現実的な要請または法令により要求されない要請については、お断りすることがあります。

## 7. 取得情報の廃棄

弊社は、弊社による通常の事業運営に照らして、合理的に不要と判断した時点で、利用者への通知や利用者の同意なくして、取得情報を破棄することができるものとし、利用者が、取得情報の破棄によって被る不利益について、弊社は一切責任を負わないものとします。

## 8. 本プライバシーポリシーに関するお問い合わせ

本プライバシーポリシーに関する質問やお問い合わせは、下記までご連絡ください。

株式会社 Opening Line

代表取締役　佐々木　亮一

〒 108-0023 　東京都港区芝浦 3-17-11 　天翔田町ビル 704

privacy@opening-line.co.jp

## 9. 本プライバシーポリシーの改定

弊社は、本プライバシーポリシーを更新することがあります。本プライバシーポリシーの重要な改定は、最新の本プライバシーポリシーとともに弊社のウェブサイトに掲載します。本プライバシーポリシーの改定は、弊社がウェブサイト上またはアプリケーション内に掲載した時点から適用されるものとします。

以上
`